.text-header {
  font-weight: 500;
  font-size: 11px;
  font-family: "Montserrat";
  color: grey;
  padding: 0.25rem 0.75rem;
}

.text-content {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat";
  color: #000;
  padding: 0.75rem;

  &.active {
    color: green;
  }
  
  &.inactive {
    color: red;
  }
}

tbody{
  tr{
    &:hover{
      background-color: #f7f7f7!important;
    }
  }
}

.edit-button {
  height: 30px;
  border-radius: 0.25rem;
  border-width: 1px;
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  background-color: white;
  color: rgb(119 119 119);
  border: 1px solid rgb(239 239 253);
  font-size: 13px;
  line-height: 1rem;

  &:hover {
    background-color: #f7f7f7;
  }

  &:active {
    background-color: #ebebeb;
  }
}

.search-input {
  padding-left: 0.5rem;
  background-color: white;
  border: 1px solid rgb(223 227 230);
  border-radius: 0.25rem;
  width: 200px;
  height: 2rem;
  font-weight: 500;
  color: black;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.cardStd{
  background-color: white;
  padding: 40px 20px 20px 20px;
  border-radius: 3px;
  border: 1px solid rgba(33, 35, 38, 0.1);
  margin-bottom: 20px;
  max-width: 800px;
  .title{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 30px;
    .icon{
      border-radius: 10px;
      background-color: #00A24F;
      padding: 15px;
      color: white;
      margin-right: 15px;
    }
  }
}
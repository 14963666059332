.imgGraphic {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.logoHistory {
  width: auto;
  height: 40px;
}

.multipleLogos {
  width: auto;
  height: 100px;
}
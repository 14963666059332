.backgroundSection {
    background-color: #008A43;
}

.titleEndSection {
  color: #4D4D4D;
}

.buttonSection {
  background-color: #00A24F;
  color: white;
  width: 150px;
}

.buttonSection:hover {
  color: white;
  background-color: #00B055;
  border-color: #00B055;
}
.form{
  border: 1px solid rgba(33, 35, 38, 0.1);
  background: white;
  padding: 20px;
}


.text-col-left-contact {
  color: gray;
}

.title-col-right-contact {
  font-size: 20px;
  font-weight: 500;
  color: #00A24F;
}

.info-col-right-contact {
  font-size: 16px;
  color: gray;
}

.buttonContact {
  background-color: #00A24F;
  color: white;
}

.buttonContact:hover {
  color: white;
  background-color: #00B055;
  border-color: #00B055;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto-Regular.ttf) format("truetype");
}

body{
  font-size: 14px!important;
}

body .btn-primary{
  background-color: #00A24F!important;
  color: white;
  border: none;
}

.title {
  font-size: 30px;
  color: #00A24F;
  font-family: "Montserrat";
}

.subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #87B81A;
  font-family: "Montserrat";
}

.text {
  font-size: 15px;
  color: gray;
  font-family: Roboto;
}

.text-red {
  font-size: 15px;
  color: #ee1c2f;
}

.text-green {
  font-size: 15px;
  color: #00a24f;
}

.text-green:hover {
  color: #02ac54;
}

.backgroundGrey {
  background-color: #f2f2f2;
}

.imgRounded {
  height: 200px;
  width: 200px;
  border-radius: 100%;
  object-fit: cover;
}

.py-10 {
  padding-top: 100px;
  padding-bottom: 30px;
}

.padding-accueil {
  padding-top: 70px;
}

p {
  font-family: roboto;
}

h2,
h3 {
  font-family: Montserrat;
  color: #00A24F;
}

.nav-color {
  color: #262626 !important;
}

.nav-color:hover {
  color: #00a24f !important;
}

.dropdown-item.active {
  background-color: #00a24f !important;
  color: white !important;
}

.dropdown-item:active {
  background-color: #00a24f !important;
  color: white !important;
}

#basic-nav-dropdown {
  text-align: center;
}

.green{
  background-color: #00A24F!important;
  color: #00A24F!important;
}
.orange{
  background-color: orange!important;
  color: orange!important;
}
.red{
  background-color: #ee1c2f!important;
  color: #ee1c2f!important;
}

.asterisque {
  color: red;
}
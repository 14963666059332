.imgQualite {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.imgQualiteEnd {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.imgQualiteRight {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.noDot {
  list-style-type: none;
  padding: 0px 0px;
}

.listQualite {
  color: #00A24F;
  font-weight: 600;
}
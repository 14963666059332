.mainContainer{
  /* background-color: #FCF2E8; */
  background-color: #F1F9F0;
  padding: 80px 0px;
  .whiteContainer{
    background-color: white;
    border-radius: 3px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
    height: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    color: #006832;
    border: 1px solid rgba(33, 35, 38, 0.1);
    &:hover{
      box-shadow: rgba(33, 35, 38, 0.1) 0px 5px 5px 2px;
    }
    .imgTitle{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .imgRoundHome{
        height: 60px;
        width: 60px;
        border-radius: 100%;
        object-fit: cover;
        position: relative;
        left: -30px;
        top: -10px;
      }
      .boxTitle{
        color: #00A24F;
        font-weight: 700;
        margin-bottom: 15px;
        padding-bottom: 15px;
        font-size: 19px;
        border-bottom: 1px #F1F9F0 solid;
        width: 100%;
      }
      .boxTitle a{
        color: #00A24F;
      }
      .content{
        display: flex;
      }
    }
    .moreBtn{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      bottom: 0;
    }
  }
}
.bgTitle{
  color: #00A24F;
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
}
.subTitle{
  color: #87B81A;
  text-align: center;
  margin-bottom: 50px;
}

.btn-primary{
  box-shadow: rgba(33, 35, 38, 0.2) 10px 10px 10px -5px;
}
.input-title {
  color: black;
  font-weight: 600;
  margin-top: 0.75rem;
}

.modal-input {
  padding-left: 1rem;
  background-color: rgb(236 238 240);
  border: 1px solid rgb(223 227 230);
  border-radius: 0.25rem;
  width: 100%;
  height: 2rem;
  margin-top: 0.5rem;
  font-weight: 500;
  color: black;
  font-size: 0.875rem;
  line-height: 1.25rem;
}


.greenBG{
    
    .textPart{
        background-color: #F1F9F0;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 5px 5px 2px;
        padding: 20px;
    }
    .contCar{
        overflow: hidden;
        padding: 0;
    }
    .left{
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }
    .right{
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }
    
    .green{
        color: #00A24F;
    }
    
    .text{
        color: #006832;
    }
}


.cardDesign {
  height: 100%;
  border: 1px solid rgba(33, 35, 38, 0.1);
  border-radius: 3px;
  overflow: hidden;
  &:hover{
    box-shadow: rgba(33, 35, 38, 0.1) 0px 5px 5px 2px;
  }
}

.link{
  font-weight: 500;
  text-decoration: underline;
}

.imgCard{

}
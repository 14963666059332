.imgVacheProduits {
  height: 250px;
  width: 250px;
  object-fit: contain;
}



.bigText{
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.performances{
  background-color: #F1F9F0;
  text-align: justify;

  .ingredient{
    font-size: 25px;
    font-weight: 700;
    color: white;
    text-align: center;
  }

  .card{
    margin-bottom: 20px;
    .text {
    font-size: 13px;
    b{
      color: #00A24F;
    }
  }

  }
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #00A24F;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
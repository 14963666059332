.caroussel{
  width: 50%;
  border-radius: 5px;
  overflow: hidden;
}
.headerCar{
  /* background: rgb(240,240,240);
  background: linear-gradient(328deg, rgba(240,240,240,1) 0%, rgba(244,244,244,1) 100%); */
  background-position: "center";
  background-size: "cover";
  height: auto;
  color: white;
  background-color: #006832;
  padding: 40px;
}

@media screen and (max-width: 800px) {
  .caroussel{
    display: none
  }
}
h1{
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: "#006832"!important;
}
h2{
  font-size: 25px;
  font-weight: 600;
  color: #87B81A;
}

.titleContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  margin-right: 20px;
}
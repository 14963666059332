.carte-class {
  height: 500px;
  object-fit: contain;
  width: 100%;
}

.deliver-class {
  height: 200px;
  object-fit: contain;
  width: 100%;
}

.details{
  border: 1px solid rgba(33, 35, 38, 0.1);
  background: white;
  padding: 20px;
}


.coloredBG{
  background-color: #F1F9F0;
  padding: 80px 0px;
  
  .imgCard {
    height: 200px;
  }
  
  .button {
    background-color: #00A24F;
    color: white;
  }
  
  .button:hover {
    color: white;
    background-color: #00B055;
    border-color: #00B055;
  }
}
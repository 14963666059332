.coloredBG{
  background-color: #F1F9F0;
}

.imgProd {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 5px 5px 2px;
}

.imgProdTruck {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 5px 5px 2px;
}

.subtitle{
  padding-bottom: 15px;
}
a {
  text-decoration: none;/*Pour que le lien ne soit plus "souligné"...*/
  color: black;
}

a:hover {
  text-decoration: none;/*Pour que le lien ne soit plus "souligné"...*/
  color: black;
}

.app_root {
  min-height: 100vh;
}
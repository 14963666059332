.nav.nav-pills .nav-link.active {
    background-color: #00a24f;
    color: white;
}

.nav.nav-pills .nav-link {
    color: #00a24f;
    border: #00a24f 1px solid;
    margin-right: 5px;
}

.colored{
    background-color: #F1F9F0;
    margin-top: 70px;
}
.backgroundFooter {
    background-color: white;
    padding: 50px 0px;
    color: #00A24F;

    .styleGrey{
        color: grey;
    }

    
    .footMenu{
        display: flex;
        flex-direction: column;
        a{
            color: #00A24F;
            margin-bottom: 10px;
        }
        .logos{
            height: 100px;
            width: 80px;
            display: flex;
        }
        .footerTitle{
            margin-bottom: 30px;
            font-size: 15px;
            color: grey;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
    
}



.iconFacebook {
    height: 20px;
    width: 20px;
    background-color: #00A24F;
    border-color: 1px #00A24F solid;
    padding: 1px;
}

.logoTalian{
    height: 80px;
    width: auto;
}
.menu-container {
  background-color: white;
  width: 250px;
  height: 100vh;
  padding: 0px 1.25rem;
  position: sticky;
  top: 76px;
  font-size: 13px;
}

.left-pipe {
  position: absolute;
  left: 0;
  background-color: #00A24F;
  height: 30px;
  border-radius: 15px;
  width: 3px;
}


.card-temoignage {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100%;
  border: 1px solid rgba(33, 35, 38, 0.1);
  border-radius: 3px;
  overflow: hidden;
  text-align: justify;

  .backtite {
    color: #00a24f;
    font-size: 40px;
  }
  .text-card {
    color: #006832;
    margin-top: -30px;
  }
  .strong-text-card {
    color: #006832;
    font-size: 15px;
    font-weight: 700;
  }
  
  .info-text-card {
    font-size: 14px;
    color: #87B81A;
    font-style: italic;
  }
  
  .imgTemoignage {
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    z-index: 4;
    border: 4px white solid;
  }
}





.cont-small{
  max-width: 880px;
  text-align: center;
}
.title{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 40px 0px;
}
.photo-app {
  border-radius: 8px;
  height: 300px;
}
.photo-news {
  height: auto;
  width: 100%;
  object-fit: cover;
  max-height: 250px;
  border-radius: 5px;
}
.logoContainer{
  .logo{
    margin-right: 20px;
    height: 80px;
  }
}
.appDescription{
  text-align: justify;
  line-height: 25px;
}
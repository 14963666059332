.forgottenPassword {
  cursor: pointer;
  margin-top: 30px;
  color: lightgrey;
  font-family: 'Montserrat';
  font-size: 12px;
}

.loginInput {
  border: 1px solid lightgrey;
  border-radius: 7px;
  height: 40px;
  min-width: 100%;
  padding: 0PX 10px;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 500;
}

.loginInput:focus {
  border: 2px solid #00A24F;
}

.title{
  font-weight: 600;
  font-size: 22px;
  // margin-bottom: 20px;
}
.subTitle{
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
}
.loginBtn{
  width: fit-content;
  padding: 5px 30px;
}

.errorMsg{
  background-color: lightpink;
  padding: 7px 20px;
  font-weight: 600;
  border: coral 1px solid;
  color: crimson;
  border-radius: 7px;
  margin-bottom: 20px;
}